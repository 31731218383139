@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;700&display=swap);
:root {
  --primary-color: #057a8d;
  --secondary-color: #f1c111;
  --white-color: #ffffff;
  --dark-color: #171819;
  --project-bg: #f0f8ff;
  --menu-bg: #0c8195;

  --title-color: #15141a;
  --gray-color: #909090;
  --link-color: #404040;
  --p-color: #666262;

  --base-font-family: "Plain-Light", sans-serif;
  --title-font-family: "Plain-Regular", sans-serif;
  --font-bold-family: "Plain-Bold", sans-serif;
  --font-weight-bold: bold;

  --h1-font-size: 42px;
  --h2-font-size: 32px;
  --h3-font-size: 24px;
  --p-font-size: 18px;
  --base-font-size: 16px;
  --menu-font-size: 14px;

  --border-radius-large: 100px;
  --border-radius-small: 5px;
}

/*---------------------------------------
     CONTACT              
  -----------------------------------------*/

.newsletter-form .form-control,
.contact-form .form-control {
  box-shadow: none;
  background: #f0f8ff;
  border: 0;
  padding: 1.7em 1.3em;
  margin: 14px 0;
}

.newsletter-form button,
.contact-form #submit-button {
  background: #057a8d;
  border-radius: 100px;
  color: #ffffff;
  color: var(--white-color);
  cursor: pointer;
  font-size: 18px;
  font-size: var(--p-font-size);
  line-height: 0px;
  padding: 1.5em 1.3em;
}

.newsletter-form button {
  background: #f1c111;
  background: var(--secondary-color);
}

.site-footer {
  background: #171819;
  padding: 7em 0 6em 0;
}

.site-footer a {
  color: #666262;
  /* color: var(--secondary-color); */
}

.site-footer a:hover,
.footer-icon {
  color: #f1c111;
  color: var(--secondary-color);
}

.footer-link li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

.copyright-text,
.footer-link,
.site-footer .social-icon {
  margin-top: 6em;
}

.copyright-text {
  margin-top: 5.3em;
}

/*---------------------------------------
     SOCIAL ICON              
  -----------------------------------------*/

.social-icon {
  position: relative;
  padding: 0;
  margin: 4em 0 0 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
}

.social-icon li a {
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  text-align: center;
}

footer h1 {
  color: #15141a;
  color: var(--title-color);
  font-size: 42px;
  font-size: var(--h1-font-size);
}

footer h2 {
  font-size: 32px;
  font-size: var(--h2-font-size);
  font-weight: 100;
}

footer h3 {
  font-size: 24px;
  font-size: var(--h3-font-size);
  font-weight: 100;
  margin-bottom: 0;
}

footer h4 {
  color: #909090;
  color: var(--gray-color);
  font-family: "Plain-Light", sans-serif;
  font-family: var(--base-font-family);
  font-size: 18px;
  font-size: var(--p-font-size);
  letter-spacing: 1px;
  text-transform: uppercase;
}

footer p {
  color: #666262;
  color: var(--p-color);
  font-size: 18px;
  font-size: var(--p-font-size);
  line-height: 1.5em;
}
b,
footer strong {
  letter-spacing: 0;
  color: #f1c111;
  color: var(--secondary-color);
}
/* */

@media screen and (max-width: 991px) {
  footer {
    text-align: center;
  }

  .copyright-text,
  .footer-link,
  .site-footer .social-icon {
    margin-top: 3em;
    padding: 0;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .footer-link,
  .site-footer .social-icon {
    margin-top: 1em;
  }

  .copyright-text {
    margin: 2.5em 0 1em 0;
  }

  .footer-link li {
    margin-left: 0;
  }
}

.about-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url(/static/media/about.c7e5415c.jpg);
  opacity: 0.8;
  text-align: center;
  /* background-position: 0 -27em; */

  padding-top: 5.5em;

  background-size: cover;

  font-family: "Josefin Sans", sans-serif;
  background-repeat: no-repeat;
  color: #ffffff;

  height: 16em;
}
.about-p {
  font-size: 1.2em;
  text-align: justify;
}
.about-container {
  padding: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 1em;
}
.clinets-logo {
  width: auto;
}

.fa-ph {
  color: #009688;
}
.hg {
  font-size: 2.5em;
}

.fa-whatsapps {
  color: green;
}

.fa-envelope-o {
  color: #d44638;
}
.contact-card {
  width: 18em;
  font-family: "Josefin Sans", sans-serif;
  box-shadow: 0px 25px 60px rgba(66, 85, 164, 0.06);

  border-radius: 1.4em !important;
  margin: auto;
  margin-top: 1em;
  height: 12em;
}

.contact-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url(/static/media/support.61c3805c.jpg);
  text-align: center;
  padding-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 15%;
  font-family: "Josefin Sans", sans-serif;

  color: #ffffff;

  height: 16em;
}

.contact {
  padding: 2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 1em 0;
}

.master-head {
  background-color: transparent;

  /* background-image: linear-gradient(
    to right,
    #7514f2,
    #622cf2,
    #4d3af1,
    #3643ee,
    #124beb
  ); */

  /* background-image: linear-gradient(
    to right top,
    #6bcdd1,
    #61c8da,
    #5dc2e1,
    #62bce6,
    #6fb4e9,
    #6db8ee,
    #6bbbf3,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  ); */
  /* 
  background-image: linear-gradient(
    to left,
    #f93943,
    #f55098,
    #ca7cd3,
    #959de8,
    #7eb2dd
  ); */

  background-image: linear-gradient(
    to right top,
    #6d327c,
    #485da6,
    #00a1ba,
    #00bf98,
    #36c486
  );
  /* background-color: #edf3ff; */

  /* start here */
  /* background-image: linear-gradient(
    109.6deg,
    rgba(237, 243, 255, 1) 11.2%,
    rgba(93, 52, 236, 1) 65.2%
  ); */

  /* background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%); */

  /* background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  ); */
  /* background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  ); */
}

.master-img {
  position: relative;
  display: block;
  padding-top: 150px;
  width: auto;
  margin-right: 0%;
  margin-top: 5.5em;
}
.master-social-img {
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  z-index: 9;
  top: 0;
}
.master-social-img img {
  max-width: 100%;
  height: auto;
  margin-top: 2em;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

@keyframes flyIn {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.flyIn {
  animation-name: flyIn;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.1s;
}

.home-title {
  margin-top: 15em;
}
.home-title h1 {
  color: white;
  font-size: 4rem;
  font-weight: 700;
}

.home-title p {
  /* color: white; */
  font-size: 1.2em;
  margin-bottom: 1em;
}
.services-title {
  margin-top: 1.5em;
  /* background-image: url("../assest/wave.png"); */

  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

.services-title__border {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background-image: linear-gradient(70deg, #b06ab3 0%, #3e2784 100%);
  /* background-image: url("../assest/wave.png"); */

  border-radius: 25%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 25px;
}

.corner {
  position: absolute;
  width: 12em;
  /* padding-top: 25%; */
  transform: rotate(180deg);
  margin-left: 65%;
  margin-top: 28%;
}

@media only screen and (max-width: 360px) {
  .home-navbar {
    left: 0 !important;
    right: 0 !important;
  }
  .home-link {
    font-size: 1.2em !important;
  }
  .home-link p {
    padding-top: 1.5em !important;
  }
  .home-title h1 {
    font-size: 3em !important;
    text-align: left;
  }
  .master-img {
    margin-top: 0;
  }
  .home-title h1 {
    font-size: 5em;
    /* text-align: center;   */
  }
  .home-title {
    margin-top: 16em;
  }
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1em;
  }
}

@media screen and (max-width: 1186px) {
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1em;
  }
}

/* For pixel 2 */

@media only screen and (max-width: 450px) {
  .home-navbar {
    left: 0 !important;
    right: 0 !important;
  }
  .home-link {
    font-size: 1.2em !important;
  }
  .home-link p {
    padding-top: 1.5em !important;
  }
  .home-title h1 {
    font-size: 3em !important;
    text-align: left;
  }
  .master-img {
    margin-top: 0;
  }
  .home-title h1 {
    font-size: 5em;
    /* text-align: center;   */
  }
  .home-title {
    margin-top: 16em;
  }
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1.9em;
  }
}

.services-card {
  padding: 1em;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  border-radius: 0 2em;
  height: 16.5em;
}
.services-card:hover {
  box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.2);
}
.card-icon {
  background: 0 0;
  width: auto;
  height: 11em;
  background-size: 60px auto;
  margin-bottom: 10px;
  left: 10px;
  display: flex;
  margin: auto;
  align-items: center;
}

.work-card a img {
  width: 100%;
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.work-card a:hover img {

  transform: scale(1.2);
  opacity: 0.8;
}
.work-card {
  overflow: hidden;
}
.work {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin-bottom: 1em;
}

.why-card {
  /* display: flex; */
  height: 4.3em;
}
.why {
  border: none !important;
  margin-bottom: 1em;
  border-radius: 10em;
  padding: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.why img {
  width: 3.5em;
  position: absolute;
}
.why-card .content {
  padding-left: 5em;
}
.why:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 360px) {
  .why {
    padding: 1.1em;
    height: 9em;
  }
  .why img {
    /* width: 3.?5em; */
    padding-top: 1.2em;
    /* position: absolute; */
  }
}
@media screen and (max-width: 1186px) {
  .why {
    padding: 1.1em;
    height: 9em;
  }
  .why img {
    padding-top: 1.2em;
  }
}

.portfoilo-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url(/static/media/portfoilo.867403b5.jpg);
  opacity: 0.8;
  text-align: center;
  /* background-position: 0 -27em; */

  padding-top: 5.5em;

  background-size: cover;

  font-family: "Josefin Sans", sans-serif;
  background-repeat: no-repeat;
  color: #ffffff;

  height: 16em;
}

.services-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url(/static/media/services.1bd65cf2.jpg);
  opacity: 0.8;
  text-align: center;
  padding-top: 5.5em;
  background-size: cover;
  font-family: "Josefin Sans", sans-serif;
  background-repeat: no-repeat;
  color: #ffffff;

  height: 16em;
}

.service-info {
  display: flex;
}
.services-link {
  color: black;
}
.services-link:hover {
  text-decoration: none;
  color: #44398f;
}

/* ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
  text-indent: -1.2em;
}

li:before {
  content: "►";
  display: block;
  float: left;
  width: 1.2em;
  /* color: #ff0000; 
} */

.service-info-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 1em;
  padding: 2em;
  border-radius: 0em 4em;
}

body {
  overflow-x: hidden;
}
* {
  scroll-behavior: smooth;
  font-family: "Josefin Sans", sans-serif;
}

.home-navbar {
  /* padding: 1.2rem 1.2rem;
  background-image: linear-gradient(70deg, #b06ab3 0%, #3e2784 100%);
  background-color: transparent; */

  background-image: none !important;
  background-color: transparent !important; /* Make the menu become transparent */

  position: absolute;
  top: 10px;

  z-index: 10;
  /* padding: 20px; */

  border-radius: 2em;

  left: 4em;
  right: 4em;

  background-color: white !important;

  /* background-color: #edf3ff !important; */
}
.nav-link {
  font-size: 0.9em;
  text-transform: uppercase !important;
  font-weight: 500;
  padding-left: 0.8em !important;
}
.home-link {
  font-size: 2em;
  font-weight: 600;
  color: #423990;
  display: flex;
}
.home-link:hover {
  text-decoration: none;
}

.home-link p {
  padding-top: 0.6em;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

