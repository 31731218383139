.services-card {
  padding: 1em;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  border-radius: 0 2em;
  height: 16.5em;
}
.services-card:hover {
  box-shadow: 0 14px 10px 0 rgba(0, 0, 0, 0.2);
}
.card-icon {
  background: 0 0;
  width: auto;
  height: 11em;
  background-size: 60px auto;
  margin-bottom: 10px;
  left: 10px;
  display: flex;
  margin: auto;
  align-items: center;
}
