.fa-ph {
  color: #009688;
}
.hg {
  font-size: 2.5em;
}

.fa-whatsapps {
  color: green;
}

.fa-envelope-o {
  color: #d44638;
}
.contact-card {
  width: 18em;
  font-family: "Josefin Sans", sans-serif;
  box-shadow: 0px 25px 60px rgba(66, 85, 164, 0.06);

  border-radius: 1.4em !important;
  margin: auto;
  margin-top: 1em;
  height: 12em;
}
