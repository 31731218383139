.portfoilo-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url("../assest/portfoilo.jpg");
  opacity: 0.8;
  text-align: center;
  /* background-position: 0 -27em; */

  padding-top: 5.5em;

  background-size: cover;

  font-family: "Josefin Sans", sans-serif;
  background-repeat: no-repeat;
  color: #ffffff;

  height: 16em;
}
