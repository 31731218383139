.contact-heading {
  background-image: linear-gradient(
      127deg,
      rgba(72, 67, 147, 0.4),
      rgba(138, 90, 131, 0.4)
    ),
    url("../assest/support.jpg");
  text-align: center;
  padding-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 15%;
  font-family: "Josefin Sans", sans-serif;

  color: #ffffff;

  height: 16em;
}

.contact {
  padding: 2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 1em 0;
}
