:root {
  --primary-color: #057a8d;
  --secondary-color: #f1c111;
  --white-color: #ffffff;
  --dark-color: #171819;
  --project-bg: #f0f8ff;
  --menu-bg: #0c8195;

  --title-color: #15141a;
  --gray-color: #909090;
  --link-color: #404040;
  --p-color: #666262;

  --base-font-family: "Plain-Light", sans-serif;
  --title-font-family: "Plain-Regular", sans-serif;
  --font-bold-family: "Plain-Bold", sans-serif;
  --font-weight-bold: bold;

  --h1-font-size: 42px;
  --h2-font-size: 32px;
  --h3-font-size: 24px;
  --p-font-size: 18px;
  --base-font-size: 16px;
  --menu-font-size: 14px;

  --border-radius-large: 100px;
  --border-radius-small: 5px;
}

/*---------------------------------------
     CONTACT              
  -----------------------------------------*/

.newsletter-form .form-control,
.contact-form .form-control {
  box-shadow: none;
  background: #f0f8ff;
  border: 0;
  padding: 1.7em 1.3em;
  margin: 14px 0;
}

.newsletter-form button,
.contact-form #submit-button {
  background: #057a8d;
  border-radius: 100px;
  color: var(--white-color);
  cursor: pointer;
  font-size: var(--p-font-size);
  line-height: 0px;
  padding: 1.5em 1.3em;
}

.newsletter-form button {
  background: var(--secondary-color);
}

.site-footer {
  background: #171819;
  padding: 7em 0 6em 0;
}

.site-footer a {
  color: #666262;
  /* color: var(--secondary-color); */
}

.site-footer a:hover,
.footer-icon {
  color: var(--secondary-color);
}

.footer-link li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

.copyright-text,
.footer-link,
.site-footer .social-icon {
  margin-top: 6em;
}

.copyright-text {
  margin-top: 5.3em;
}

/*---------------------------------------
     SOCIAL ICON              
  -----------------------------------------*/

.social-icon {
  position: relative;
  padding: 0;
  margin: 4em 0 0 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
}

.social-icon li a {
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  text-align: center;
}

footer h1 {
  color: var(--title-color);
  font-size: var(--h1-font-size);
}

footer h2 {
  font-size: var(--h2-font-size);
  font-weight: 100;
}

footer h3 {
  font-size: var(--h3-font-size);
  font-weight: 100;
  margin-bottom: 0;
}

footer h4 {
  color: var(--gray-color);
  font-family: var(--base-font-family);
  font-size: var(--p-font-size);
  letter-spacing: 1px;
  text-transform: uppercase;
}

footer p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  line-height: 1.5em;
}
b,
footer strong {
  letter-spacing: 0;
  color: var(--secondary-color);
}
/* */

@media screen and (max-width: 991px) {
  footer {
    text-align: center;
  }

  .copyright-text,
  .footer-link,
  .site-footer .social-icon {
    margin-top: 3em;
    padding: 0;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .footer-link,
  .site-footer .social-icon {
    margin-top: 1em;
  }

  .copyright-text {
    margin: 2.5em 0 1em 0;
  }

  .footer-link li {
    margin-left: 0;
  }
}
