@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;700&display=swap");

body {
  overflow-x: hidden;
}
* {
  scroll-behavior: smooth;
  font-family: "Josefin Sans", sans-serif;
}

.home-navbar {
  /* padding: 1.2rem 1.2rem;
  background-image: linear-gradient(70deg, #b06ab3 0%, #3e2784 100%);
  background-color: transparent; */

  background-image: none !important;
  background-color: transparent !important; /* Make the menu become transparent */

  position: absolute;
  top: 10px;

  z-index: 10;
  /* padding: 20px; */

  border-radius: 2em;

  left: 4em;
  right: 4em;

  background-color: white !important;

  /* background-color: #edf3ff !important; */
}
.nav-link {
  font-size: 0.9em;
  text-transform: uppercase !important;
  font-weight: 500;
  padding-left: 0.8em !important;
}
.home-link {
  font-size: 2em;
  font-weight: 600;
  color: #423990;
  display: flex;
}
.home-link:hover {
  text-decoration: none;
}

.home-link p {
  padding-top: 0.6em;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
