.work-card a img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.work-card a:hover img {
  -webkit-transform: scale(1.2);

  transform: scale(1.2);
  opacity: 0.8;
}
.work-card {
  overflow: hidden;
}
.work {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin-bottom: 1em;
}
