.master-head {
  background-color: transparent;

  /* background-image: linear-gradient(
    to right,
    #7514f2,
    #622cf2,
    #4d3af1,
    #3643ee,
    #124beb
  ); */

  /* background-image: linear-gradient(
    to right top,
    #6bcdd1,
    #61c8da,
    #5dc2e1,
    #62bce6,
    #6fb4e9,
    #6db8ee,
    #6bbbf3,
    #69bff8,
    #52cffe,
    #41dfff,
    #46eefa,
    #5ffbf1
  ); */
  /* 
  background-image: linear-gradient(
    to left,
    #f93943,
    #f55098,
    #ca7cd3,
    #959de8,
    #7eb2dd
  ); */

  background-image: linear-gradient(
    to right top,
    #6d327c,
    #485da6,
    #00a1ba,
    #00bf98,
    #36c486
  );
  /* background-color: #edf3ff; */

  /* start here */
  /* background-image: linear-gradient(
    109.6deg,
    rgba(237, 243, 255, 1) 11.2%,
    rgba(93, 52, 236, 1) 65.2%
  ); */

  /* background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%); */

  /* background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  ); */
  /* background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  ); */
}

.master-img {
  position: relative;
  display: block;
  padding-top: 150px;
  width: auto;
  margin-right: 0%;
  margin-top: 5.5em;
}
.master-social-img {
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  z-index: 9;
  top: 0;
}
.master-social-img img {
  max-width: 100%;
  height: auto;
  margin-top: 2em;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

@keyframes flyIn {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
  }
}

.flyIn {
  -webkit-animation-name: flyIn;
  animation-name: flyIn;
  -webkit-animation-duration: 4.5s;
  animation-duration: 4.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.home-title {
  margin-top: 15em;
}
.home-title h1 {
  color: white;
  font-size: 4rem;
  font-weight: 700;
}

.home-title p {
  /* color: white; */
  font-size: 1.2em;
  margin-bottom: 1em;
}
.services-title {
  margin-top: 1.5em;
  /* background-image: url("../assest/wave.png"); */

  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

.services-title__border {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background-image: linear-gradient(70deg, #b06ab3 0%, #3e2784 100%);
  /* background-image: url("../assest/wave.png"); */

  border-radius: 25%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 25px;
}

.corner {
  position: absolute;
  width: 12em;
  /* padding-top: 25%; */
  transform: rotate(180deg);
  margin-left: 65%;
  margin-top: 28%;
}

@media only screen and (max-width: 360px) {
  .home-navbar {
    left: 0 !important;
    right: 0 !important;
  }
  .home-link {
    font-size: 1.2em !important;
  }
  .home-link p {
    padding-top: 1.5em !important;
  }
  .home-title h1 {
    font-size: 3em !important;
    text-align: left;
  }
  .master-img {
    margin-top: 0;
  }
  .home-title h1 {
    font-size: 5em;
    /* text-align: center;   */
  }
  .home-title {
    margin-top: 16em;
  }
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1em;
  }
}

@media screen and (max-width: 1186px) {
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1em;
  }
}

/* For pixel 2 */

@media only screen and (max-width: 450px) {
  .home-navbar {
    left: 0 !important;
    right: 0 !important;
  }
  .home-link {
    font-size: 1.2em !important;
  }
  .home-link p {
    padding-top: 1.5em !important;
  }
  .home-title h1 {
    font-size: 3em !important;
    text-align: left;
  }
  .master-img {
    margin-top: 0;
  }
  .home-title h1 {
    font-size: 5em;
    /* text-align: center;   */
  }
  .home-title {
    margin-top: 16em;
  }
  .navbar-collapse {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
  }
  .footer-h1 {
    padding-left: 1.9em;
  }
}
