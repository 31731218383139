/* ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
  text-indent: -1.2em;
}

li:before {
  content: "►";
  display: block;
  float: left;
  width: 1.2em;
  /* color: #ff0000; 
} */

.service-info-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 1em;
  padding: 2em;
  border-radius: 0em 4em;
}
