.why-card {
  /* display: flex; */
  height: 4.3em;
}
.why {
  border: none !important;
  margin-bottom: 1em;
  border-radius: 10em;
  padding: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.why img {
  width: 3.5em;
  position: absolute;
}
.why-card .content {
  padding-left: 5em;
}
.why:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 360px) {
  .why {
    padding: 1.1em;
    height: 9em;
  }
  .why img {
    /* width: 3.?5em; */
    padding-top: 1.2em;
    /* position: absolute; */
  }
}
@media screen and (max-width: 1186px) {
  .why {
    padding: 1.1em;
    height: 9em;
  }
  .why img {
    padding-top: 1.2em;
  }
}
